<template>
  <div class="dashContain">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "./dash/style.scss";
@import "./global.scss";
</style>
